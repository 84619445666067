/**
 * @Author: HS
 * @Date: 2021/6/17 10:12 上午
 * @Description: demo
 * @LastEditTime: 2021/6/17 10:12 上午
 * @LastEditors:
 * @FilePath: src/views/Demo/Index/Index.ts
*/
import { Component, Vue, Provide, Prop, Watch, Mixins } from 'vue-property-decorator';
import { FinishedPrimaryQueryListApi } from '@/network/modules/demo';
import echarts from "echarts";

import { mapGetters } from 'vuex';
import { VueChatEmoji, emojis } from 'vue-chat-emoji'
import { DemoEnum } from "@/enum/demo";
require("vue-chat-emoji/dist/vue-chat-emoji.min.css");
// const area = require('@/utils/area')

@Component({
    components: {
        Emoji: VueChatEmoji
    },
    computed: {
        ...mapGetters(["iData",'iType'])
    }
})
export default class Demo extends Vue {
    @Provide() iLading: boolean = false;

    @Watch('iData',{immediate:false, deep:true})
    @Watch('iType')
    private iDataChange():void {
        console.log((this as any).iData)
        console.log((this as any).iType)
    }

    selectedEmoji(args:any) {
        console.log(args); /* return {...}*/
    }

    created() {

        (this as any).$base.repeatRouter((this as any).$LoginConfig.login.path)
        (this as any).$encode(123123)

        // console.log(DemoEnum)
       // console.log((this as any).$base.isLogin())
        // console.log((this as any).$encode(123123))
            // console.log(area)
        // this.FinishedPrimaryQueryListFn();
        // console.log((this as any).iData)
        // console.log((this as any).iType)
        // localStorage.setItem("isAAA",'3d6fcddb0a9aac02d8c2d7ad765e4a6adfd520fbd4b529a8')
    }

    aaa(){
        // this.$store.commit('SET_DATA','改变')
        // this.$store.commit('SET_TYPE','TYPE改变')
        const _this:any = this;
        this.$router.push(_this.$LoginConfig.login.path)
    }

    async FinishedPrimaryQueryListFn(){
        const _this:any = this;
        _this.iLading = true
        const data = await FinishedPrimaryQueryListApi({
            commodityType: 1,
            type: 1,
            pageNum: 1,
            pageSize: 15,
        })
        const { code , rows, msg } = data
        const a = setTimeout(()=>{this.iLading = false; clearTimeout(a)},500)
        if(code != 200 ){
            _this.$message.error(msg);
            return
        }
        console.log(rows[0])
    }


}
